import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../containers/layout'
import Winner from '../components/Winner'
import SEO from '../components/seo'

export const query = graphql`
  query($slug: String!) {
    sanityAwardWinner(slug: { current: { eq: $slug } }) {
      _id
      firstName
      lastName
      _rawBody
      awardCHAMP {
        title
      }
      startYear
      endYear
      userRole {
        title
      }
      school_name {
        title
      }
      sport {
        title
      }
      photos {
        _key
        asset {
          fluid(maxWidth: 640) {
            sizes
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
          }
        }
      }
    }
  }
`

const WinnerPage = ({data: {sanityAwardWinner: page}}) => {
  // console.log({page})
  return (
    <Layout title='test'>

      <SEO title='title' description='description' />

      <Winner
        firstName={page.firstName}
        lastName={page.lastName}
        award={page.awardCHAMP.title}
        startYear={page.startYear}
        endYear={page.endYear}
        userRole={page.userRole.title}
        school={page.school_name}
        sports={page.sport}
        bio={page._rawBody}
        photos={page.photos}
      />
    </Layout>

  )
}

export default WinnerPage
