import React from 'react'
import Image from 'gatsby-image'
import {Link} from 'gatsby'
import PortableText from './portableText'
import Container from './container'

import {Carousel} from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

// import './slideshow.module.css'

import styles from '../components/winner.module.css'

// import styles from './page.module.css'

const Winner = ({firstName, lastName, award, startYear, endYear, userRole, school, sports, bio, photos}) => {
  // console.log({bio})
  const year = () => {
    return startYear === endYear ? startYear : `${startYear}-${endYear}`
  }
  return (
    <>

      <article className={styles.root} >
        <div className={styles.backLink}> <Link to='/recognition'>Back to the Winner's Directory</Link> </div>
        <Container>
          <div className={styles.pageTitleWrapper}>
            <h1 className={styles.pageTitle}>{firstName} {lastName}</h1>
          </div>
          <div>
            <div className={styles.mainContent}>
              <div style={{paddingRight: '30px', width: '100%'}}>

                <div className={styles.topWinnerWrapper}>
                  {/* userRole */}
                  <span className={styles.userRole}>{userRole} </span>

                  {/* School */}
                  {school && (<><span> at </span><span className={styles.school}>{school.title} </span></>)}

                  {/* Year Range */}
                  <div className={styles.years}> In {year()}, {firstName} was awarded the</div>

                  {/* Award */}
                  <div className={styles.award}>{award}</div>
                </div>
                {/* Sport */}
                {sports.length > 0 && (<div className={styles.sports}>
                  <span>Sport(s):</span>
                  <ul>
                    {sports.map(sport => (
                      <li key={sport.title} className={styles.sport}>
                        {sport.title}
                      </li>
                    ))}
                  </ul>
                </div>)}
                {/* Bio */}
                {bio && <PortableText blocks={bio} />}

                {/* Photos */}
                {photos.length > 0 && (<div className={styles.photos}>

                  <Carousel showThumbs={false} autoPlay dynamicHeight showStatus={false} useKeyboardArrows>
                    {photos.map(photo => (
                      <div key={photo._key}>
                        <Image fluid={photo.asset.fluid} alt='' />
                      </div>
                    ))}
                  </Carousel>

                </div>)}
              </div>

            </div>
          </div>
        </Container>
      </article>
    </>
  )
}
export default Winner
